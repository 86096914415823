<template>
  <div>
      <ValidationObserver ref="form">
      <div >
        <h4 class="pb-3">{{trans('auth-create-new-password',299)}}</h4>
        <app-password v-model="password" class="mb-2 app-local-inputs" @enter="resetPassword" :validatorName="trans('auth-password',299)" :label="trans('auth-password',299)"/>
        <app-password v-model="password_confirmation" @enter="resetPassword" :validatorName="trans('auth-confirm-password',299)" class="app-local-inputs" :label="trans('auth-confirm-password',299)"/>
          <ValidationObserver ref="custom">
        <app-input v-model="error" type="hidden" class="mb-2 app-local-inputs"  validatorRules="required" :validatorCustomMessage="{ required: errorMessage }"/>
        </ValidationObserver>
      </div>
        </ValidationObserver>
      <div class="text-center">
            <app-button type="primary" class="my-4 app-local-inputs" :loading="loading" :block="true" size="md" @click="resetPassword">{{trans('auth-save-password',299)}}</app-button>
      </div>
  </div>
</template>
<script>

import axiosMobile from "../../../util/axiosMobile";
import {ValidationObserver} from "vee-validate";
import AppAlerts from '@core/scripts/AppAlerts';
import AppPassword from "@core/components/AppPassword.vue"

  export default {
    
    components: {
        AppPassword,  
        ValidationObserver
    },
    props:["badToken", "step", "token"],

    watch: {
        "password":{
            handler: function(val) {
                if(val != undefined &&  this.error == ''){
                  this.error= 'false';
                  this.$refs.custom.validate();
                }
                
            }
        },
          "password_confirmation":{
            handler: function(val) {
                if(val != undefined &&  this.error == ''){
                  this.error= 'false';
                  this.$refs.custom.validate();
                }
                
            },
        }
    },

    data() {
      return {
        password_confirmation: '',
        password: '',
        error: 'false',
        rememberMe: false,
        loading: false,
        errorMessage: '',
        alertClass: new AppAlerts()
      };
    },
    methods: {
      resetPassword() {
       
       
        this.$refs.form.validate().then(async (result) => {
          if (!result) {
              return false;
          }else{

             if(this.password_confirmation != this.password){
                this.error='';
                this.errorMessage = this.trans('auth-password-not-same',299)
                await this.$nextTick();
                this.$refs.custom.validate()
                return;
              }
              this.loading = true;
              axiosMobile.post("v2/oauth/resetPassword", {
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                    token: this.token
                })
              .then( async (response) => {
                  this.$emit("update:step", 2)
              })
              .catch((error) => {
                if(error.response.data.error == 'bad_password'){
                  this.error='';
                  this.errorMessage = this.trans('auth-password-same-as-before-used',299)
                  this.$nextTick();
                  this.$refs.custom.validate()
                  return;
                }else if (error.response.data.error == 'token_invalid'){
                  this.alertClass.openAlertWarning(this.trans('auth-token-expired',299)).then(res => {
                    this.$emit("update:badToken", true)
                    this.$emit("update:step", 2)
                    return
                  });
                }else{
                  console.error(`error during request: ${error}`);
                }
                  
              }).finally(() => {
                  this.loading = false;
              }); 
        }
        });
        }
      }
    };
</script>

<style>
.app-local-inputs .app-input-error-ellips {
    overflow: initial !important;
    color: #d92d20 !important;
    white-space: break-spaces;
}

.text-error{
  color: #d92d20 !important;
}
</style>

<style scoped>
.vertical-center {
  min-height: calc(100% -64px); 
  min-height: calc(100vh - 64px); 


  display: flex;
  align-items: center;
}



.app-local-image-logo{
  margin-left: -20px;
}
</style>
