import Vue from 'vue'

// axios
import axios from 'axios'
import router from '../routes/router'
import envVariables from "./envVariables";

var envVariablesObj = new envVariables();

const axiosIns = axios.create({
  withCredentials: true,
  baseURL: envVariablesObj.getBaseURLforMobileAxios(),
  headers: {
    Accept: 'application/json',
  }
})

axiosIns.interceptors.response.use(function (response) {


  return response
}, function (error) {
  //Obsługa błędów
  if (error.response) { //wyjatki na reset hasla
    if (error.response.data.error != 'bad_password' && error.response.data.error != 'token_invalid' && (error.response.status === 404 || error.response.status === 400)) {

      router.push({ name: "404-error-page" });

    }else if(error.response.status === 500){
      
      router.push({name: "500-error-page"});

    }else if(error.response.status === 452){//File not found - nasz customy status code pula od 452 - 499 jest pusta
      router.push({name: "500-error-page"});
    }
    const isAuthenticated = localStorage.getItem('user');
    if (error.response.status === 401 && isAuthenticated) {
        localStorage.removeItem('user');
        router.push({ name: 'Login' , query: { redirect: window.location.hash.substr(1) } })
    }

  }
  return Promise.reject(error)
})

Vue.prototype.$http = axiosIns
export default axiosIns
