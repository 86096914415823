<template>
  <div>

      <div class="mb-3">
        <div v-if="badToken" class="mb-2 custom-font-size"> {{trans('auth-password-changed-badtoken-info',299)}} </div>
        <div v-else class="mb-2 custom-font-size"> {{trans('auth-password-is-set',299)}} </div>
        
      </div>
  </div>
</template>
<script>



  export default {
    props:["badToken"],
    data() {
      return {
        loading: false,
      };
    },
   
    };
</script>

<style scoped>
.vertical-center {
  min-height: calc(100% -64px); 
  min-height: calc(100vh - 64px); 


  display: flex;
  align-items: center;
}

.app-local-image-logo{
  margin-left: -20px;
}

.custom-font-size{
  font-size: 18px;
}
</style>
